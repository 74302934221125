


































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaNewPrelab5Q3',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        procedureAnswer: null,
        language: 'en',
      },
      questions: [
        {
          en: 'How would you extract catalase from a vegetable source without using organic solvents in the lab? Answer in 2 sentences or less.',
          fr: 'Comment pouvez-vous extraire la catalase d’une source végétale sans utiliser des solvants organiques au laboratoire ? Répondez en deux phrases ou moins.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
